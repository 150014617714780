import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'

import axios from 'axios'
import VueMoment from 'vue-moment';
import Multiselect from 'vue-multiselect'
import VueNativeSock from 'vue-native-websocket'

Vue.use(VueNativeSock, 'ws://localhost', {
  connectManually: true,  
  reconnection: true, // (Boolean) whether to reconnect automatically (false)
  reconnectionAttempts: 5, // (Number) number of reconnection attempts before giving up (Infinity),
  reconnectionDelay: 10000, // (Number) how long to initially wait before attempting a new (1000),
  store: store
})

// Add a request interceptor
axios.interceptors.request.use(function (config) {
  config.headers.Authorization = store.state.token;
  return config;
});
Vue.prototype.$http = axios

// register multiselect globally
Vue.component('multiselect', Multiselect)

// Install BootstrapVue
Vue.use(BootstrapVue)
Vue.use(IconsPlugin)
Vue.use(VueMoment)


// Automatic import of all Base* components and make them global components
const requireComponent = require.context('./components/', false, /Base[\w-]+\.vue$/)
requireComponent.keys().forEach(filename => {
  const componentConfig = requireComponent(filename)
  const componentName = filename.replace(/^\.\//, '').replace(/\.\w+$/, '')
  Vue.component(componentName, componentConfig.default || componentConfig)
})

// Styles
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
//import 'vue-multiselect/dist/vue-multiselect.min.css';
import './assets/styles/vue-multiselect-bs4.scss' // BS4 ok-ish style for vue multiselect - with extra size adjustments in site.scss
import './assets/styles/site.scss'

Vue.config.productionTip = false

// Pluralize filter - add an s when more than one :)
Vue.filter('pluralize', function (value, number) {
  if (!value) return ''
  if (!number) return value
  if (typeof number != 'number') return value
  if (number > 1) return `${value}s`
  return value
})

Vue.prototype.$confirmIt = function (text) { // Special $confirmIt global true/false (then)
  return new Promise((resolve) => {
    this.$bvModal.msgBoxConfirm(text, {
      title: 'Please Confirm',
      size: 'sm',
      buttonSize: 'sm',
      okVariant: 'danger',
      okTitle: 'YES',
      cancelTitle: 'NO',
      footerClass: 'p-2 justify',
      hideHeaderClose: false,
      centered: true
    })
      .then(value => {
        resolve(value === true);
      })
      .catch(err => {
        console.log("error", err);
        resolve(false);
      })
  });
}

Vue.prototype.$showIt = function (text) { // Special $showIt global
  return new Promise((resolve) => {
    this.$bvModal.msgBoxOk(text, {
      title: 'Information',
      size: 'sm',
      buttonSize: 'sm',
      okVariant: 'primary',
      headerClass: 'p-2 border-bottom-0',
      footerClass: 'p-2 border-top-0',
      centered: true
    })
      .then(value => {
        resolve(value);
      })
      .catch(err => {
        console.log("error", err);
        resolve(false);
      })
  });
}

Vue.prototype.$lightIt = function (src, title) {
  const h = this.$createElement;
  const titleVNode = h('div', { domProps: { innerHTML: title } });
  const messageVNode = ([
    h('b-img', {
      props: {
        src: src,
        thumbnail: true,
        center: true,
        fluid: true,
      }
    })
  ]);
  // ModalSettings
  let modalSettings = {
    title: titleVNode,
    buttonSize: 'sm',
    centered: true,
    size: 'lg',
    okTitle: 'Close',
    dialogClass: 'lightit-modal',
    headerBgVariant: 'dark',
    headerTextVariant: 'light',
    headerClass: 'border-secondary rounded-0',
    bodyBgVariant: 'dark',
    bodyTextVariant: 'light',
    contentClass: 'border-0',
    footerBgVariant: 'dark',
    footerTextVariant: 'light',
    footerClass: 'border-secondary rounded-0',
    okVariant: 'light',
  };
  if (!title) {
    modalSettings.hideHeader = true;
    modalSettings.hideFooter = true;
  }
  // We must pass the generated VNodes as arrays
  this.$bvModal.msgBoxOk([messageVNode], modalSettings);
}

// Make a toast
Vue.prototype.$toastIt = function (content, opts) {
  
  // Basic default titles
  const titles = {
    'success': 'Success',
  };

  // Default options merge
  let options = Object.assign({}, {
    variant: '',
    solid: true,
    append: true,     // Well - should not be changed between calls, but hey.. it's here already!
    autoHideDelay: 5000,
    html: true,
  }, opts);

  // Set fallback title if none is provided
  if ( !options.title ) {
    options.title = titles[options.variant] ?? 'Information';
  }

  // Do not hide?
  if (options.noAutoHide) {
    delete options.autoHideDelay;
  }

  // Make TOAST!!!
  this.$bvToast.toast(content, options);
}

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')