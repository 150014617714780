<template>
  <div class="logs_page">

    <!-- grey intro -->
    <div class="bg-site-light pt-4 pb-4 mb-4">
      <div class="container-fluid px-5">
        <div class="d-flex flex-column flex-sm-row justify-content-sm-between align-items-sm-end py-2 text-center text-sm-left">
          <div class="flex-sm-fill">
            <h1 class="h3 font-w700 mb-0">
              Integration Logs
            </h1>
          </div>
          <div class="mt-3 mt-sm-0 ml-sm-3 flex-shrink-0">
            <!--
            <a href="javascript:void(0)" class="btn btn-outline-secondary mr-1"><b-icon icon="plus-square-fill"></b-icon> New Order</a>
            <a href="javascript:void(0)" class="btn btn-secondary"><b-icon icon="gear-fill"></b-icon></a>
            -->
          </div>
        </div>
      </div>
    </div>

    <div class="px-md-3">
      <div class="container-fluid pt-3 pb-1 mb-5 bg-white shadow">

        <div class="card p-2 pt-3 bg-light border rounded-0" v-show="userHasRole('superadmin')">
          <b-row>
            <b-col xl="6" lg="6" md="12" class="my-1 align-self-end">
              <b-form-group
                label="Account"
                label-for=""
                label-cols-sm="3"
                label-align-sm="right"
                label-size="sm"
                class="mb-1"
              >
                <b-select
                  :options="userAccounts"
                  v-model="table_filters.account"
                  size="sm"
                  value-field="account"
                  text-field="name"
                  placeholder="Select account to show..."
                  @change="fetchData"
                >
                  <template #first>
                    <b-form-select-option :value="null" disabled>-- Please select an account --</b-form-select-option>
                  </template>
                </b-select>
                <small class="text-muted">Integration required</small> - <small class="text-muted"><b>Admin</b></small>
              </b-form-group>
            </b-col>
          </b-row>
        </div>

        <template v-if="! loading && ! error">
          <b-table :items="logs" :fields="fields" show-empty>
            <template #cell(created_at)="data">
              {{ createdAt(data.item.created_at) }}
            </template>
            <template #cell(tags)="data">
              {{ data.item.tags.join(', ') }}
            </template>
          </b-table>
        </template>
      </div>
    </div>

    

  </div>
</template>



<script>
import { mapGetters } from 'vuex'

export default {
  name: 'IntegrationLogs',

  components: {
  },

  data() {
    return {
      table_filters: {
        account: null,
      },
      loading: true,
      error: null,
      logs: [],
      fields: [
        {
          key: 'created_at',
          label: 'Created',
          sortable: true
        },
        {
          key: 'identifier',
          label: 'Identifier',
          sortable: true
        },
        {
          key: 'log',
          label: 'Text',
          sortable: true,
        },
        {
          key: 'tags',
          label: 'Type',
          sortable: true,
        }
      ],
    }
  },

  computed: {
    ...mapGetters([
    ]),
    ...mapGetters('user', [
      'userHasRole',
      'userAccounts',
    ]),
  },

  created() {
    // Set Sidebar Active Part
    this.$store.commit("SET_SITE_SETTINGS", {'sidebar_current': 'logs'});
    // Fetch data
    this.fetchData();
  },

  methods: {
    createdAt(timestamp, locale = 'sv-SE') {
      const date = new Date(Number(timestamp) * 1000); // Convert to milliseconds
      const dateString = date.toLocaleDateString(locale); // "YYYY-MM-DD"
      const timeString = date.toLocaleTimeString(locale, { 
        hour: '2-digit', 
        minute: '2-digit' 
      }); // "HH:mm"
      return `${dateString} ${timeString}`;
    },
    
    fetchData() {
      // Reset all
      this.error = null;
      this.logs = [];
      this.loading = true;

      let fetchPath = `${this.$store.state.api_base_url}/logs`;
      fetchPath += this.table_filters.account ? `?account=${this.table_filters.account}` : '';

      this.$http.get(fetchPath)
      .then((result) => {
        // console.log(result);
        if ( result.status === 200 ) {
          this.logs = result.data.items;
          this.loading = false;
        }
        if ( result.status !== 200 ) {
          this.error = result.data?.message || 'Could not load data!';
          this.$toastIt(this.error, {variant: 'danger', autoHideDelay: 2000});
        }
      });
    },
  }
}
</script>

<style lang="css">
</style>