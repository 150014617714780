<template>
  <div id="app" class="bg-light">

    <!-- login page - no menu -->
    <div v-if="isLoginPage">
      <router-view></router-view>
    </div>
    <!-- end: login page -->

    <!-- site logged in -->
    <div v-if="!isLoginPage && currentUser" class="page-wrapper basic-theme" :class="{toggled: sidebar_visible}">
        
      <a id="show-sidebar" class="btn btn-sm btn-light text-dark" href="#" @click.prevent="toggleSidebar()">
        <b-icon icon="list"></b-icon>
      </a>
      
      <nav class="sidebar d-flex flex-column noselect">
        <div class="position-relative d-flex align-items-center justify-content-between pl-3 pr-1 pt-2" >
          <!-- <span><small>alfredssons.printerapi.com</small></span> -->
          <div class="text-muted font-weight-bold "></div>
          <div class="sidebar-close" @click.prevent="toggleSidebar()" tabindex="0">
            <b-icon icon="x" font-scale="2"></b-icon>
          </div>
        </div>
        <router-link class="d-flex align-items-center" :class="{active: sidebar_current=='home'}" :to="{ name: 'Dashboard' }">
          <b-icon icon="house" font-scale="1" class="mr-2"></b-icon>
          Home
        </router-link>
        <router-link class="d-flex align-items-center" :class="{active: sidebar_current=='jobs'}" :to="{ name: 'Jobs' }">
          <b-icon icon="stickies" font-scale="1" class="mr-2"></b-icon>
          Jobs
        </router-link>
        <router-link class="d-flex align-items-center" :class="{active: sidebar_current=='products', disabled: userHasRole('xsuperadmin')}" :to="{ name: 'Products' }">
          <b-icon icon="box-seam" font-scale="1" class="mr-2"></b-icon>
          Products
        </router-link>
        <router-link class="d-flex align-items-center" :class="{active: sidebar_current=='claims'}" :to="{ name: 'Claims' }">
          <b-icon icon="lightning" font-scale="1" class="mr-2"></b-icon>
          Claims
        </router-link>
        <router-link class="d-flex align-items-center" :class="{active: sidebar_current=='returns'}" :to="{ name: 'Returns' }">
          <b-icon icon="arrow-return-left" font-scale="1" class="mr-2"></b-icon>
          Returns
        </router-link>
        <router-link class="d-flex align-items-center" :class="{active: sidebar_current=='logs', disabled: userHasRole('xsuperadmin')}" :to="{ name: 'IntegrationLogs' }">
          <b-icon icon="lightning" font-scale="1" class="mr-2"></b-icon>
          Logs
        </router-link>

        <router-link v-if="userHasAnyOfRoles(['superadmin', 'puadmin'])" class="d-flex align-items-center" :class="{active: sidebar_current=='templating'}" :to="{ name: 'Templating' }">
          <b-icon icon="file-text" font-scale="1" class="mr-2"></b-icon>
          Files
        </router-link>

<!--    
        <router-link v-if="userHasRole('superadmin')" class="d-flex align-items-center" :class="{active: sidebar_current=='settings'}" :to="{ name: 'UserAdminMessages', params: {id: currentUser.id} }">        
          <b-icon icon="gear" font-scale="1" class="mr-2"></b-icon>
          Settings
        </router-link>
-->

        <!-- <router-link :to="{ path: '/user/12345/settings' }">User (alpha)</router-link> -->
        
        <a v-if="getUploaderLink" :href="getUploaderLink" target="_blank">
          <b-icon icon="upload" font-scale="1" class="mr-2"></b-icon>
          Uploader
        </a>
                <router-link :to="{ path: '/'}" class="neveractive "><b-icon icon="box-arrow-left" font-scale="1" class="mr-2"></b-icon>Logout</router-link>

        <!-- <router-link :to="{ name: 'Products'}">Products</router-link> -->
        <!-- <a href="https://alfredssons.com/#kontakt" target="_blank" class="neveractive"><b-icon icon="box-arrow-up-right"></b-icon> Contact</a> -->
         <div class="mt-auto mb-3">
          <div class="pr-5 pl-3">
          <img src="https://assets-global.website-files.com/65157192b5e4ce1a2cc99bf5/6525021e1571f6661d53306a_Alfredssons_liggLeft_pos.svg" alt="">
           </div>
          </div>
        
      </nav>

      <main class="page-content">
        <router-view />
      </main>

    </div>

  </div>
</template>

<script>
import{ mapActions, mapGetters } from 'vuex';

export default {
  data() {
    return {}
  },

  mounted() {
    // Make sidebar hidden by default on smaller screens
    this.saveSiteSettings({sidebar_visible: window?.innerWidth > 768})
  },

  computed: {
    ...mapGetters([
      'getUploaderLink',
      'getSiteSetting',
    ]),
    ...mapGetters('user', ['currentUser', 'userHasRole', 'userHasAnyOfRoles']),

    sidebar_visible() {
      return this.getSiteSetting('sidebar_visible')
    },
    sidebar_current() {
      return this.getSiteSetting('sidebar_current');
    },

    isLoginPage() {
      return ( this.$route.name === 'Login' );
    },

    hasSessionToken() {
      return this.$store.state.token !== false;
    },

    sessionToken() {
      return this.$store.state.token;
    }
  },
  
  methods: {
    ...mapActions([
      'saveSiteSettings',
    ]),
    ...mapActions('ticket', [
      'fetchActiveTickets',
    ]),

    toggleSidebar() {
      this.saveSiteSettings({sidebar_visible: !this.sidebar_visible})
    },
  },

  watch: {
    hasSessionToken( val ) {
      if ( ( val===false || val===true ) && !this.isLoginPage ) {
        this.$router.push({ name: 'Login' });
      }

      // This is were we attach the websocket (after confirmed login)
      /*
      if ( val ) {
        this.$root.$connect( this.$store.state.ws_server_url + val);
      }
      */
    }
  },

  beforeUpdate() {
    // Check so token is in place, alse redirect to login page on every update
    if ( this.hasSessionToken===false && !this.isLoginPage ) {
      this.$router.push({ name: 'Login' });
    }
    
    // This is were we attach the websocket (after confirmed login)
    /*
    if ( this.sessionToken ) {
      this.$root.$connect( this.$store.state.ws_server_url + this.sessionToken);
    }
    */
  },

  beforeCreate() {
    // Make auth session persistent.
    // Restore Token if set in session
    if ( this.$store.state.token === false && sessionStorage.getItem('token') !== null ) {
      this.$store.state.token = window.sessionStorage.getItem('token');
      // Load initial data once logged in.
      this.$store.dispatch('fetchInitialData');
      // Tickets (test to split it up)
      this.$store.dispatch('ticket/fetchActiveTickets', {});
    }
  }

}
</script>

<style scoped>
.disabled {
  pointer-events: none;
  position: relative;
}
.disabled::after {
  content: '';
  background: #45638a80;
  width: 80%;
  height: 2px;
  top: 47%;
  position: absolute;
}

.nselect {
  -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
     -khtml-user-select: none; /* Konqueror HTML */
       -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}
</style>
<!-- <style src="vue-multiselect/dist/vue-multiselect.min.css"></style> -->