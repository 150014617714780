import countryData from "@/assets/countries2.json"

export default {
  //
  // API
  //

  auth_base_url: `${process.env.VUE_APP_BASE_URL}`, //dashboard-dev.printerapi.com',
  api_base_url: `${process.env.VUE_APP_BASE_API}`, //dashboard-dev.printerapi.com/ui',
  ws_server_url: `ws:${process.env.VUE_APP_BASE_URL}:9000/`, //'ws://dashboard-dev.printerapi.com:9000/',
  admin_base_url: `${process.env.VUE_APP_BASE_ADMIN_URL}`, // production site

  socket: {
    isConnected: false,
    message: '',
    reconnectError: false,
  },

  //
  // Site (sidebar etc)
  //

  site_settings: {
    sidebar_visible: true,
    sidebar_current: 'home',
  },

  //
  // Auth / Token
  //

  token: false,

  //
  // Specials: Uploader link (temp)
  //

  uploader_link: '',

  //
  // Page data (filters etc)
  //

  page_settings: [],

  //
  // Global data
  //

  page_data_orders: false,  
  orders: [], // Global -NOT- the page specific ????
  products: [],
  integrations: [],

  //
  // Collection of manually set/removed pending requests (strings/names) - TEST
  //

  pending_requests: [],

  //
  // Lists
  //
  
  countries: countryData,

  product_types: [
    {
      id: 'preset',
      name: 'Preset',
      fields: [
        {
          name: 'preset',
          type: 'text',
          source: 'presets',
        },
        {
          name: 'price',
          type: 'numeric'
        }
      ]
    },
    {
      id: 'standard_include',
      name: 'Standard Addon',
      fields: [
        {
          name: 'sku',
          type: 'text',
          source: 'includes',
        },
        {
          name: 'price',
          type: 'numeric'
        }
      ]
    },
    {
      id: 'custom_include',
      name: 'Custom Addon',
      fields: [
        {
          name: 'sku',
          type: 'text',
        },
        {
          name: 'price',
          type: 'numeric'
        }
      ]
    },
    {
      id: 'foto',
      name: 'Template - Foto',
      fields: [
        {
          name: 'title',
          type: 'text',
        },
        {
          name: 'subtitle',
          type: 'text'
        }
      ]
    },
    {
      id: 'citat',
      name: 'Template - Citat',
      fields: [
        {
          name: 'text',
          type: 'textarea'
        },
        {
          name: 'by',
          type: 'text'
        }
      ]
    }
  ],

 
  //
  // Claim data (maybe move down 1 level, not to create collisions with other images etc...)
  //
  originalOrder:null,
  damagedOrders:[],
  itemPosition:[],
  newOrder:{},
  images:[],
  originalImages:[],
  claimCategory:{transport_damage:false,lost_transport:false,defect_packaging:false,wrong_packaging:false},
  refund:false,
  commentText:''
}